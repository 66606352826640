<template>
  <div>
    <div class="row">
      <div class="icon-wrapper cursor-pointer" @click="telecharger">
        <b-button variant="outline-primary" size="sm" class="ml-2 mb-2">
          Rélevé du {{ date }}
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-card-code>
          <div>
            <span class="font-weight-black"> {{ toPrint.type }} : </span>
            {{ toPrint.concerne }}
          </div>

          <hr />
          <div v-if="!toPrint.showMontantColumn">
            <span class="font-weight-black"> Solde actuel du compte : </span>
            {{ $thousandSeparator(toPrint.solde) }}
          </div>
          <div>
            <span class="font-weight-black"> Du : </span>
            {{
              toPrint.periodeDu ? reformatDate(toPrint.periodeDu) : periodeDu
            }}
          </div>
          <div>
            <span class="font-weight-black"> Au : </span>
            {{
              toPrint.periodeAu
                ? reformatDate(toPrint.periodeAu)
                : "------------"
            }}
          </div>
        </b-card-code>
      </div>
      <div class="col-6">
        <b-card-code>
          <div v-if="toPrint.compteDe">
            <span class="font-weight-black"> Compte : </span>
            {{ toPrint.compteDe }}
          </div>
          <div v-if="toPrint.avecCaisseDe">
            <span class="font-weight-black"> Caisse concernée : </span>
            {{ toPrint.avecCaisseDe }}
          </div>
          <div v-if="toPrint.avecSocieteDe">
            <span class="font-weight-black"> Société concernée : </span>
            {{ toPrint.avecSocieteDe }}
          </div>

          <div>
            <span class="font-weight-black"> Total des entrées : </span>
            {{ $thousandSeparator(totalEntree) }}
          </div>
          <div>
            <span class="font-weight-black"> Total des sorties : </span>
            {{ $thousandSeparator(totalSortie) }}
          </div>
          <div>
            <span class="font-weight-black"> Solde de la période : </span>
            {{ $thousandSeparator(totalSolde) }}
          </div>
        </b-card-code>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-column mx-1">
        <div>
          <b-table responsive :fields="fields" :items="toPrint.cahier">
            <template #cell(entree)="data">
              <div>
                {{
                  data.item.entree
                    ? $thousandSeparator(data.item.entree)
                    : "-----"
                }}
              </div>
            </template>
            <template #cell(sortie)="data">
              <div>
                {{
                  data.item.sortie
                    ? $thousandSeparator(data.item.sortie)
                    : "-----"
                }}
              </div>
            </template>
            <template #cell(solde)="data">
              <div>
                {{
                  data.item.solde
                    ? $thousandSeparator(data.item.solde)
                    : "-----"
                }}
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  BContainer,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BAlert, BLink } from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    EmptyList,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BBadge,
    BContainer,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BLink,
  },
  data() {
    return {
      items: [],
      itemsVehicule: [],
      itemsPaiement: [],
      fields: [
        { key: "created_at", label: "Date de l'opération" },
        { key: "libelle", label: "Libellé" },
        { key: "compte.libelle", label: "Compte concerné" },
        { key: "entree", label: "Entrée" },
        { key: "sortie", label: "Sortie" },
        { key: "solde", label: "Solde" },
      ],
      toPrint: {},
      showSortie: true,
      SocietePrincipale: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    periodeDu() {
      let minDate;
      let journal = this.toPrint.cahier ? this.toPrint.cahier : [];
      if (journal.length > 0) {
        minDate = journal[0].created_at;
        journal.forEach((element) => {
          if (element.created_at < minDate) minDate = element.created_at;
        });
      }
      return minDate;
    },

    date() {
      var today = new Date();
      let annee = today.getFullYear();
      let mois = ("0" + (today.getMonth() + 1)).slice(-2);
      let jour = ("0" + today.getDate()).slice(-2);
      var date = jour + "/" + mois + "/" + annee;
      return date;
    },

    totalEntree() {
      let entree = 0;
      if (this.toPrint.cahier) {
        this.toPrint.cahier.forEach((element) => {
          entree += element.entree;
        });
      }
      return entree;
    },

    totalSortie() {
      let sortie = 0;
      if (this.toPrint.cahier) {
        this.toPrint.cahier.forEach((element) => {
          sortie += element.sortie;
        });
      }
      return sortie;
    },

    totalSolde() {
      return this.totalEntree - this.totalSortie;
    },

    cc() {
      return this.hasPersonnel() ? "Personnel Concerné" : "Caisses concernées";
    },

    hasSociete() {
      let counter = 0;
      if (this.toPrint.headers) {
        this.toPrint.headers.forEach((element) => {
          if (element.value == "societe") counter++;
        });
      }
      return counter > 0 ? true : false;
    },

    hasFsr() {
      let counter = 0;
      if (this.toPrint.headers) {
        this.toPrint.headers.forEach((element) => {
          if (element.value == "retirer_par") counter++;
        });
      }
      return counter > 0 ? true : false;
    },

    hasSource() {
      let counter = 0;
      if (this.toPrint.headers) {
        this.toPrint.headers.forEach((element) => {
          if (element.value == "source") counter++;
        });
      }
      return counter > 0 ? true : false;
    },
  },
  mounted() {
    if (this.toPrint.cahier) {
      this.items = this.toPrint.cahier;
    }
    let data = this.$router.currentRoute.params.data;
    if (data && !data.showSoldeColumn) {
      if (!data.showMontantColumn) {
        data.headers.pop();
      } else {
        this.showSortie = false;
      }
    }
    this.toPrint = data;
    this.societesData();
  },

  methods: {
    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = ("0" + (date.getMonth() + 1)).slice(-2);
        let jour = ("0" + date.getDate()).slice(-2);
        let date = jour + "/" + mois + "/" + annee;
        return date.toString();
      }
      return date;
    },

    hasPersonnel() {
      let counter = 0;
      if (this.toPrint.headers) {
        this.toPrint.headers.forEach((element) => {
          if (element.text == "Personnel") counter++;
        });
      }
      return counter > 0 ? true : false;
    },

    async societesData() {
      await this.$http.get("/societes").then((response) => {
        this.SocietePrincipale = response.data.data[0];
      });
    },

    reformatDate(date) {
      if (date) {
        date = new Date(date);
        let annee = date.getFullYear();
        let mois = date.getMonth() + 1;
        let jour = date.getDate();
        date = jour + "/" + mois + "/" + annee;
        return date.toString();
      }
      return date;
    },

    telecharger() {
      window.print();
      //  window.close();
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

.v-data-table__wrapper table,
.v-data-table__wrapper table tbody tr td,
.v-data-table__wrapper table thead tr td {
  border: 1.2px solid rgb(27, 26, 26) !important;
}

.br {
  border-right: 1.2px dashed rgb(27, 26, 26) !important;
}
// .bl {
// 	border-left: 1.2px solid rgb(27, 26, 26) !important;
// }

@page {
    size: landscape;
}

.col-5 {
  padding: 0px 12px !important;
}

.v-application--wrap {
  min-height: 80vh !important;
}

.invoice-box {
  padding: 0%;
  font-size: 16px;
  line-height: 24px;
  color: #555;
  //   margin: 10px auto !important;
}

@media only screen and (max-width: 1000px) {
  .block-text {
    min-width: 400px;
  }

  .img {
    padding-top: 200px !important;
  }
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.block-text {
  z-index: 2;
}

.child1 {
  position: absolute;
  .img {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
  }
  z-index: 1;
}

.child2 {
  right: 0;
  margin-top: 10px !important;
  padding-right: 20px !important;
  font-size: 1.5em;
  font-weight: bold;
}

.child3 {
  top: 100px;
  z-index: 1;
  font-size: 4em !important;
  // margin: auto 50px !important;
  border-radius: 10px;
}

#title1 {
  font-size: 20px;
  font-weight: 400;
}

#facturetitle {
  font-size: 30px;
}

#scope1 {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 0 4px 0;
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
}

#tableau_avertissement .v-data-table__wrapper table tr td {
  font-size: 0.8em !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

#societe {
  padding: 0px !important;
  margin: 0px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  height: 1px !important;
  /* width: 10% !important; */
}

.col-3,
.col-9 {
  padding: 0px !important;
}

#vue_globale {
  width: 100% !important;
}

.logo {
  /* min-width: 150px !important;
			max-width: 150px !important; */

  width: 40%;
  max-width: 150px;
  height: auto;
}

.v-application p {
  padding: 0px !important;
  margin: 0px !important;
}

#texte {
  font-size: 12px !important;
}
</style>